.footer {
    background-image: url('footer.png' );
    background-size: 100vw 400px;
    background-repeat: no-repeat;
    width: 100vw;
    height: 300px;
    bottom:0px;
    display: flex;
    align-items: center;
}

.footer-intro {
    padding-left: 10vw;
    color: #fff;
    text-align: center;
    margin-top: 30px;
    font-size: 13px;
}


.footer-title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 5px;
}

.footer-contents {
    text-align: left;
}

.footer-logo img {
    width: 12vw;
    margin: 10vw;    
}

.footer-contents img {
    margin: 0 5px;
    height: 20px;
    width: 20px;
}

@media screen and (max-width: 600px){
    .footer-intro {
        padding: 0px;
        font-size: 10px;
        position: relative;
        top: -20px;
    }

    .footer {
        background-image: url('phone\ footer.png' );
        height: 200px;
        background-repeat: no-repeat;
        bottom:0px;
        display: flex;
        align-items: center;
    }

    .footer-logo img {
        top: -10px;
        position: relative;
    }
}
