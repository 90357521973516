.navbar {
    top: 20px;
}
/* .navbar {
    padding-left: 10px;
}
.navbar .navbar-item{
    margin: 10px;
    padding: 10px 100px;
}





.navbar a:hover{
    background-color: transparent !important;
} */

.underline{
    margin: 20px 0;
    text-align: center;
}

.underline span{
    padding: 10px 20px;
    border: 1px solid transparent;
}

.underline span:hover {
    padding: 10px 20px;
    cursor: pointer;
    border-left: 1px solid #FFFFFF;
}

.underline span:visited {
    display:none;
}

.logo {
    height: 80px;
  }

  .logo img {
    text-align: center;
    height: 100%;
}
