.name-bottom{
    margin-top: -80px;
    margin-bottom:  80px;
    text-align: center;
    color: white;
font-size: 1.5em;

}

.arrow {
position: absolute;
top:80%;
left: 48%;
}

.arrow img{
    width: 20px;
}

.header-background-image {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.563) 0%, rgba(0, 0, 0, 0) 91.65%), url("./Headernotext.png");
        width: 100%;
        height: 1080px;
        position: relative;
        z-index: -1;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
}

@media screen and (max-width: 600px){
    .header-background-image {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.563) 0%, rgba(0, 0, 0, 0) 91.65%), url("./Phoneheader.png");
            width: 100%;
            height: 700px;
            position: relative;
            z-index: -1;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
    }
}