.menu-items{
    display: flex;
    width: 100%;
}

.menu-item {
    padding: 10px;
    width: 33%;
    text-align: center;
}

.menu-item img{
    width: 100%;
}

.menu-name {
    text-transform: uppercase;
    font-size: 0.8em;
}

.menu-price {
    font-size: 0.8em;
}