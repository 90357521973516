/* .logo {
    margin: 10px 0;
    width: 100%;
    height: 10vh;
    text-align: center;
  } */

  /* .logo img {
      height: 100%;
  } */

  @media (min-width:768px){
    .let-me-test {
        display: flex;
      /* align-items: center; */
      justify-content: space-evenly;
      }
    
  }
  

 .let-me-test > * {
    position: absolute;
  }

  /* @media (max-width: 768px) {
    nav.navbar {
      background: rgba(4, 4, 4, 0.226);
    }
  } */