.menu img {
    width: 100%;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.menu-button {
    background-image: url('shape1.png' );
    /* background-size: 20vw 10vh; */
    background-size: 160px 60px;
    color: #fff;
    width: 160px;
    height: 60px;
    border: none;
    margin: 10px 0;
}

.container-buttons{
    margin-top: 40px;
    margin-bottom: 30px;
}

.background-img-test{
    width: 100%;
    /* background-size: 1440px 855px container; */
    height: 855px;
    position: relative;
    z-index: -1;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.563) 0%, rgba(0, 0, 0, 0) 91.65%), url("./Menu\ header.png");
    background-repeat: no-repeat;
    background-position:center;
}

@media screen and (min-width: 600px){
    .carousel-sth {
        padding: 1vh 4vw;
        width: 70%;
        left: 15%;
    }    
}
.img-font{
    position: absolute;
    bottom: -350px;
    right: 50%;
    text-align: center;
    color: rgb(251, 251, 251);
    font-size: 50px;
}