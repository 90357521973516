video {
    width: 100%;
    position: relative;
    z-index: -1;
}
.about-body {
    display: flex;
    text-align: center;
    margin-bottom: 20px;
}

.about-body img{
    width: 40%;  
}
.about-logo {
   text-align: center;
}
.about-logo img {
    width: 120px;
    height: 80px;
}

.about-body .about-contents{
    margin-top: 20px;
    /* margin-left: 5vw; */
    width: 100%;  
    font-weight:bold;
    font-size: 1em;
    text-transform: uppercase;
}

@media screen and (max-width: 600px) {
    .about-body{
       align-items: center;
    }

    .about-body img {
        display: none;
    }
    .about-body .about-contents{
    margin-left: 0vw;
    margin-top: 30px;

        width: 100%; 
    }
  }

.about-button {
    background-image: url('shape1.png' );
    /* background-size: 20vw 10vh; */
    background-size: 160px 60px;
    color: #fff;
    width: 160px;
    height: 60px;
    border: none;
    margin: 10px 0;
    font-size: 1.5vw;
    /* padding-top: 17px; */
}

.about-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.extra-contents{
   font-weight: 900px;
}

.element {
    all: none;
  }

.test {
    width: 100%;
}

.email {
    text-transform: lowercase;
}

.about-us-text{
    text-align: center;
    position: absolute;
    float: left;
    left: 27%;
    padding-top: 20px;
    color: #fff;
    width: 50%;
    font-size: 3vw;
    text-transform: uppercase;
}

.about-us-text .sub{
    font-size: 2vw;
}

.about-us-text .title{
    margin-bottom: 3%;
}